var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"common-rules-settings"},[(_vm.isVisible(_vm.CommonRulesFields.EnabledCommonFilter))?_c('switch-input',{attrs:{"setup":{
      'func': _vm.configInputSetup,
      'args': {
        'model': _vm.getModel,
        'key': 'enabled_common_filter',
        'hasAnchor': true,
      }
    }}}):_vm._e(),(_vm.getModel['enabled_common_filter'])?_c('nested-content',[(_vm.isVisible(_vm.CommonRulesFields.Remove))?_c('switch-input',{attrs:{"setup":{
        'func': _vm.configInputSetup,
        'args': {
          'model': _vm.getModel,
          'key': 'remove',
          'prefix': (_vm.filterType + "_"),
          'hasAnchor': true,
        }
      }}}):_vm._e(),(_vm.getModel['remove'])?_c('div',[(_vm.isVisible(_vm.CommonRulesFields.Limit))?_c('number-input',{attrs:{"setup":{
          'func': _vm.configInputSetup,
          'args': {
            'model': _vm.getModel,
            'key': 'limit',
            'min': 0,
            'prefix': (_vm.filterType + "_"),
            'max': Number.MAX_SAFE_INTEGER,
            'hasAnchor': true,
          }
        }}}):_vm._e(),(_vm.isVisible(_vm.CommonRulesFields.Limit_interval))?_c('time-unit-input',{attrs:{"setup":{
          'func': _vm.configInputSetup,
          'args': {
            'model': _vm.getModel,
            'key': 'limit_interval',
            'prefix': (_vm.filterType + "_"),
            'clearable': false,
            'hasAnchor': true,
            'units': [ _vm.StepUnit.Minute, _vm.StepUnit.Hour, _vm.StepUnit.Day, _vm.StepUnit.Week ],
            'step': _vm.StepUnit.Minute,
          }
        }}}):_vm._e(),(_vm.isVisible(_vm.CommonRulesFields.Remove_timeout))?_c('time-unit-input',{attrs:{"setup":{
          'func': _vm.configInputSetup,
          'args': {
            'model': _vm.getModel,
            'key': 'remove_timeout',
            'disabled': !_vm.$store.getters.isChatAtLeastAdvanced || !_vm.$store.getters.isChatLicenseExists,
            'tags': !_vm.$store.getters.isChatAtLeastAdvanced || !_vm.$store.getters.isChatLicenseExists ? [{color: _vm.$store.state.themeConfig.config.tariffs.advanced.color, text: 'Advanced'}] : null,
            'hasAnchor': true,
          },
        }}}):_vm._e()],1):_vm._e(),(_vm.isVisible(_vm.CommonRulesFields.Notice_before_ban_count))?_c('number-input',{attrs:{"setup":{
        'func': _vm.configInputSetup,
        'args': {
          'model': _vm.getModel,
          'key': 'notice_before_ban_count',
          'min': 0,
          'max': Number.MAX_SAFE_INTEGER,
          'hasAnchor': true,
          'prefix': _vm.customTranslationPrefix,
        }
      }}}):_vm._e(),(_vm.getModel.notice_before_ban_count > 0 && !_vm.getModel.warning_message.length)?_c('a-alert',{attrs:{"type":"error","show-icon":"","message":_vm.$t('filters_warning_messages_is_empty')}}):_vm._e(),(_vm.showNoticeBeforeBanSetting)?_c('nested-content',[_c('a-alert',{staticClass:"mb-2",attrs:{"show-icon":""}},[_c('div',{attrs:{"slot":"message"},domProps:{"innerHTML":_vm._s(_vm.$t('filter_warning_message_comments_alert').toString())},slot:"message"})]),(_vm.isVisible(_vm.CommonRulesFields.Warning_message))?_c('multi-message-editor-with-media-input',{attrs:{"setup":{
          'func': _vm.configInputSetup,
          'args': {
            'model': _vm.getModel,
            'key': 'warning_message',
            'targetToUpload': function () { return _vm.groupUploadTarget; },
            'availableButtonsTypes': _vm.defaultMessageEditorButtons,
            'editorMode': _vm.MediaEditorMode.TelegramShort,
            'base-api-url': 'api.chatkeeper.app/cabinet',
            'placeholders': _vm.warnMessageEditorProps,
            'hasAnchor': true,
            'prefix': _vm.customTranslationPrefix,
          },
        }}}):_vm._e()],1):_vm._e(),(_vm.isVisible(_vm.CommonRulesFields.Ban_type))?_c('select-input',{attrs:{"setup":{
        'func': _vm.configInputSetup,
        'args': {
          'model': _vm.getModel,
          'key': 'ban_type',
          'options': _vm.muteMediaFilters.includes(_vm.filterType) ? _vm.muteMediaOptions('ban_type_') : _vm.defaultBanTypesLowerCaseOptions('ban_type_'),
          'clearable': false,
          'hasAnchor': true,
        }
      }}}):_vm._e(),(_vm.getModel.ban_type === 'mute' && _vm.filterType === 'flood')?_c('switch-input',{attrs:{"setup":{
        'func': _vm.configInputSetup,
        'args': {
          'model': _vm.$store.state.chatState.configCopy,
          'key': 'filters_mute_media_separately',
          'tags': !_vm.$store.getters.isChatAtLeastAdvanced ? [{color: _vm.$store.state.themeConfig.config.tariffs.advanced.color, text: 'Advanced'}] : null,
          'disabled': !_vm.$store.getters.isChatAtLeastAdvanced,
          'hasAnchor': true,
        }
      }}}):_vm._e(),(_vm.isVisible(_vm.CommonRulesFields.Ban_notice))?_c('switch-input',{attrs:{"setup":{
        'func': _vm.configInputSetup,
        'args': {
          'model': _vm.getModel,
          'key': 'ban_notice',
          'hasAnchor': true,
        }
      }}}):_vm._e(),(_vm.showBanNoticeSetting)?_c('nested-content',[(_vm.isVisible(_vm.CommonRulesFields.Ban_message))?_c('multi-message-editor-with-media-input',{attrs:{"setup":{
          'func': _vm.configInputSetup,
          'args': {
            'model': _vm.getModel,
            'key': 'ban_message',
            'targetToUpload': function () { return _vm.groupUploadTarget; },
            'availableButtonsTypes': _vm.filterButtons,
            'editorMode': _vm.MediaEditorMode.TelegramShort,
            'base-api-url': 'api.chatkeeper.app/cabinet',
            'placeholders': _vm.banMessageEditorProps,
            'hasAnchor': true,
          },
        }}}):_vm._e()],1):_vm._e(),(_vm.isVisible(_vm.CommonRulesFields.Round_limit_time))?_c('switch-input',{attrs:{"setup":{
        'func': _vm.configInputSetup,
        'args': {
          'model': _vm.getModel,
          'key': 'round_limit_time',
          'hasAnchor': true,
        }
      }}}):_vm._e()],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }