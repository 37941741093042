












import Component from 'vue-class-component'
import Vue from 'vue'
import { Emit } from 'vue-property-decorator'

@Component
export default class ExtraSettingsBackButton extends Vue{

  @Emit('navBack')
  navBack():null {
    return null
  }

}
