


















import NotAvailableOptionsOverlay from '@/components/NotAvailableOptionsOverlay.vue'
import { getTariffTranslationKey } from '@/includes/helpers/tariffHelper'
import TariffsTagsHelper from '@/mixins/TariffsTagsHelper'
import { InputSetups } from '@/mixins/input-setups'
import { BRAND_TAG } from '@/includes/constants'

import { TagData } from 'piramis-base-components/src/components/Tags/types'
import { UseFields } from 'piramis-base-components/src/components/Pi'
import { SelectOption } from 'piramis-base-components/src/logic/types'

import { Component, Mixins, Prop } from 'vue-property-decorator'
import { get } from 'lodash'

@Component({
  components: {
    NotAvailableOptionsOverlay
  },
  data() {
    return {
      get,
      getTariffTranslationKey
    }
  },
})
export default class NewCommentsSelect extends Mixins<UseFields, InputSetups, TariffsTagsHelper>(UseFields, InputSetups, TariffsTagsHelper) {
  @Prop({ type: Object, required: true }) field!: any

  get tag(): Array<TagData> | null {
    const CommentsModeTag = this.getTagsByFieldKey('comments_mode')
    const hasCommentsModeInChatConfigOption = !this.getTagsByFieldKey('comments_mode')

    if (hasCommentsModeInChatConfigOption && !this.$store.state.chatState.chat.brand) {
      return BRAND_TAG
    }
    if (!hasCommentsModeInChatConfigOption) {
      return CommentsModeTag
    }

    return null
  }

  commentsModeOptions(): Array<SelectOption> {
    return [
      {
        label: this.$t('comments_mode_options_none').toString(),
        value: 'None'
      },
      {
        label: this.$t('comments_mode_options_comments').toString(),
        value: 'Comments'
      },
      {
        label: this.$t('comments_mode_options_group_messages').toString(),
        value: 'GroupMessages'
      },
      {
        label: this.$t('comments_mode_options_comments_not_members').toString(),
        value: 'CommentsNotMembers'
      },
      {
        label: this.$t('comments_mode_options_comments_members').toString(),
        value: 'CommentsMembers'
      }
    ]
  }
}
