
































































































































import { InputSetups } from '../../../../../mixins/input-setups'
import { VirtualNewFields } from '@/views/chat/filters/FilterTypes'
import FilterFieldsMixins from '@/views/chat/filters/include/FilterFieldsMixins'
import Placeholders from "@/mixins/placeholders/placeholders";
import SelectOptionsMixin from '@/mixins/SelectOptionsMixin'

import { UseFields } from 'piramis-base-components/src/components/Pi'
import { MediaEditorMode } from "piramis-base-components/src/components/MessageEditorWithMedia/types";

import { Mixins } from 'vue-property-decorator'
import Component from 'vue-class-component'
import { get } from 'lodash'

@Component({
  data() {
    return {
      VirtualNewFields,
      MediaEditorMode,
    }
  },
})
export default class VirtualNewSettings extends Mixins(UseFields, InputSetups, FilterFieldsMixins, Placeholders, SelectOptionsMixin) {

  get getModel(): any {
    const rootModelPath = `${ this.storePath }.${ this.filterType }`
    return get(this, rootModelPath)
  }

  get showRemoveEdited(): boolean {
    return this.getModel['remove_edited']
  }

  get showFirstMessageRemove(): boolean {
    return this.getModel['first_message_remove']
  }

  get showNewLimitWarningMessage(): boolean {
    return this.getModel['remove_edited'] || this.getModel['first_message_remove'] || this.getModel['new_limit_msg_count'] || this.getModel['new_limit']
  }
}
