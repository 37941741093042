var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"virtual-new-settings"},[(_vm.isVisible(_vm.VirtualNewFields.Remove_edited))?_c('switch-input',{attrs:{"setup":{
      'func': _vm.configInputSetup,
      'args': {
        'model': _vm.getModel,
        'key': 'remove_edited',
        'hasAnchor': true
      }
    }}}):_vm._e(),(_vm.showRemoveEdited)?_c('nested-content',[(_vm.getModel['remove'])?_c('a-alert',{attrs:{"show-icon":"","message":_vm.$t('remove_edited_extra_disabled_message')}}):_vm._e(),(!_vm.getModel['remove'])?[(_vm.isVisible(_vm.VirtualNewFields.Remove_edited_timeout))?_c('number-input',{attrs:{"setup":{
          'func': _vm.configInputSetup,
          'args': {
            'model': _vm.getModel,
            'key': 'remove_edited_timeout',
            'min': 0,
            'max': Number.MAX_SAFE_INTEGER,
            'hasAnchor': true,
            'disabled': _vm.getModel['remove']
          }
        }}}):_vm._e(),(_vm.isVisible(_vm.VirtualNewFields.Remove_edited_msg))?_c('number-input',{attrs:{"setup":{
          'func': _vm.configInputSetup,
          'args': {
            'model': _vm.getModel,
            'key': 'remove_edited_msg',
            'min': 0,
            'max': Number.MAX_SAFE_INTEGER,
            'hasAnchor': true,
            'disabled': _vm.getModel['remove']
          }
        }}}):_vm._e()]:_vm._e()],2):_vm._e(),(_vm.isVisible(_vm.VirtualNewFields.First_message_remove))?_c('switch-input',{attrs:{"setup":{
      'func': _vm.configInputSetup,
      'args': {
        'model': _vm.getModel,
        'key': 'first_message_remove',
        'prefix': (_vm.filterType + "_"),
        'hasAnchor': true
      }
    }}}):_vm._e(),(_vm.showFirstMessageRemove)?_c('nested-content',[(_vm.isVisible(_vm.VirtualNewFields.First_message_ban_type))?_c('select-input',{attrs:{"setup":{
        'func': _vm.configInputSetup,
        'args': {
          'model': _vm.getModel,
          'key': 'first_message_ban_type',
          'options': _vm.defaultBanTypesLowerCaseOptions('first_message_ban_type_'),
          'clearable': false,
          'hasAnchor': true
        }
      }}}):_vm._e()],1):_vm._e(),(_vm.isVisible(_vm.VirtualNewFields.New_limit))?_c('number-input',{attrs:{"setup":{
      'func': _vm.configInputSetup,
      'args': {
        'model': _vm.getModel,
        'key': 'new_limit',
        'prefix': "old_",
        'min': 0,
        'max': Number.MAX_SAFE_INTEGER,
        'hasAnchor': true
      }
    }}}):_vm._e(),(_vm.isVisible(_vm.VirtualNewFields.New_limit_msg_count))?_c('number-input',{attrs:{"setup":{
      'func': _vm.configInputSetup,
      'args': {
        'model': _vm.getModel,
        'key': 'new_limit_msg_count',
        'min': 0,
        'max': Number.MAX_SAFE_INTEGER,
        'hasAnchor': true
      }
    }}}):_vm._e(),(_vm.showNewLimitWarningMessage)?_c('multi-message-editor-with-media-input',{attrs:{"setup":{
      'func': _vm.configInputSetup,
      'args': {
        'model': _vm.getModel,
        'key': 'new_limit_warning_message',
        'targetToUpload': function () { return _vm.groupUploadTarget; },
        'availableButtonsTypes': _vm.defaultMessageEditorButtons,
        'editorMode': _vm.MediaEditorMode.TelegramShort,
        'base-api-url': 'api.chatkeeper.app/cabinet',
        'hasAnchor': true,
        'placeholders': _vm.FILTERS_PLACEHOLDERS
      }
    }}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }