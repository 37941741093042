import { render, staticRenderFns } from "./VirtualNewSettings.vue?vue&type=template&id=1bc8b4de&scoped=true&"
import script from "./VirtualNewSettings.vue?vue&type=script&lang=ts&"
export * from "./VirtualNewSettings.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1bc8b4de",
  null
  
)

export default component.exports