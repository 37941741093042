













































import { InputSetups } from '@/mixins/input-setups'

import ConfigField from 'piramis-base-components/src/components/ConfigField/ConfigField.vue'
import { UseFields } from 'piramis-base-components/src/components/Pi'
import WindowResizeMixin from 'piramis-base-components/src/logic/helpers/WindowResizeMixin'
import HighlightAnchor from 'piramis-base-components/src/components/HighlightAnchor.vue'

import { Mixins, Prop } from 'vue-property-decorator'
import Component from 'vue-class-component'

@Component({
  components: {
    ConfigField,
    HighlightAnchor
  }
})
export default class FromTo extends Mixins<UseFields, InputSetups, WindowResizeMixin>(UseFields, InputSetups, WindowResizeMixin) {
  @Prop() field!: any

  get filterFromTargetObject(): string {
    return this.field.from
  }

  set filterFromTargetObject(value: string | null) {
    this.setFromToModelByPropKey('from', value)
  }

  get filterToTargetObject(): string {
    return this.field.to
  }

  set filterToTargetObject(value: string | null) {
    this.setFromToModelByPropKey('to', value)
  }

  setFromToModelByPropKey(key: 'from' | 'to', timeValue: string | null): void {
    this.$store.commit('EXEC_CALLBACK_IN_STORE', {
      callback: () => {
        this.$set(this.field, key, timeValue)
      }
    })
  }

  resetFromTo():void {
    this.filterToTargetObject = null
    this.filterFromTargetObject = null
  }

}
