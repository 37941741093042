
















import { InputSetups } from '../../../../../mixins/input-setups'
import FilterFieldsMixins from '@/views/chat/filters/include/FilterFieldsMixins'
import TariffsTagsHelper from '@/mixins/TariffsTagsHelper'

import { UseFields } from 'piramis-base-components/src/components/Pi'

import Component from 'vue-class-component'
import { Mixins } from 'vue-property-decorator'

@Component
export default class HandleUserBio extends Mixins<UseFields, InputSetups, FilterFieldsMixins, TariffsTagsHelper>(UseFields, InputSetups, FilterFieldsMixins, TariffsTagsHelper) {
}
