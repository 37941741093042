var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"strict-mode"},[(_vm.isVisible(_vm.StrictModeFields.Strict_mode))?_c('switch-input',{attrs:{"setup":{
      'func': _vm.configInputSetup,
      'args': {
        'model': _vm.getModelFromConfigRoot,
        'key': (_vm.filterType + "_strict_mode"),
        'prefix': (_vm.filterType + "_"),
        'hasAnchor': true,
      }
    }}}):_vm._e(),(_vm.showUseCustomDict && _vm.isVisible(_vm.StrictModeFields.Custom_dict))?_c('nested-content',[_c('switch-input',{attrs:{"setup":{
        'func': _vm.configInputSetup,
        'args': {
          'model': _vm.getModelFromConfigRoot,
          'key': 'censor_word_use_custom_dict',
          'hasAnchor': true,
        }
      }}}),(_vm.showCustomDict)?_c('nested-content',[_c('chips-input',{attrs:{"setup":{
          'func': _vm.configInputSetup,
          'args': {
            'model': _vm.getModelFromConfigRoot,
            'key': 'censor_word_custom_dict',
            'hasAnchor': true,
          }
        }}})],1):_vm._e()],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }