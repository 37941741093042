







































































































































































































import { CommonRulesFields } from '@/views/chat/filters/FilterTypes'
import { InputSetups } from '@/mixins/input-setups'
import ExtraSettingsBackButton from '@/views/chat/filters/components/ExtraSettingsBackButton.vue'
import FilterFieldsMixins from '@/views/chat/filters/include/FilterFieldsMixins'
import SelectOptionsMixin from '@/mixins/SelectOptionsMixin'
import Placeholders from '@/mixins/placeholders/placeholders'

import {
  MediaEditorMode,
  MessageEditorWithMediaData
} from 'piramis-base-components/src/components/MessageEditorWithMedia/types'
import { UseFields } from 'piramis-base-components/src/components/Pi/index'
import { AvailableButtonsTypes } from 'piramis-base-components/src/components/MessageEditorWithMedia/components/ButtonsConstructor/types'

import { Mixins, Prop } from 'vue-property-decorator'
import Component from 'vue-class-component'

@Component({
  components: {
    ExtraSettingsBackButton,
  },
  data() {
    return {
      MediaEditorMode,
      CommonRulesFields,
    }
  },
})
export default class CommonRulesSettings extends Mixins(UseFields, InputSetups, FilterFieldsMixins, Placeholders, SelectOptionsMixin) {

  @Prop({ type: Object }) editorProps!: {
    [CommonRulesFields.Warning_message]: {
      placeholders: any
    } | undefined,
    [CommonRulesFields.Ban_message]: {
      placeholders: any
    } | undefined
  } | undefined

  muteMediaFilters: Array<string> = [ 'sticker', 'voice', 'gif', 'sticker_pack', 'audio', 'video', 'video_note' ]

  get warnMessageEditorProps():any {
    if (this.editorProps && this.editorProps[CommonRulesFields.Warning_message]) {
      return this.editorProps[CommonRulesFields.Warning_message]?.placeholders
    }

    return this.FILTERS_PLACEHOLDERS
  }

  get banMessageEditorProps():any {
    if (this.editorProps && this.editorProps[CommonRulesFields.Ban_message]) {
      return this.editorProps[CommonRulesFields.Ban_message]?.placeholders
    }

    return this.FILTERS_PLACEHOLDERS
  }

  get showNoticeBeforeBanSetting(): boolean {
    return this.getModel[CommonRulesFields.Notice_before_ban_count] > 0
  }

  get showBanNoticeSetting(): boolean {
    return this.getModel[CommonRulesFields.Ban_notice]
  }

  get filterButtons(): AvailableButtonsTypes {
    const hasActionButton = this.getModel['ban_message'].some((m:MessageEditorWithMediaData) => m.buttons && m.buttons.some(bg => bg.some(b => b.type === 'Action')))

    if ([ 'ban', 'mute' ].includes(this.getModel['ban_type']) || hasActionButton) {
      return this.filterMessageEditorButtons
    } else {
      return this.defaultMessageEditorButtons
    }
  }
}
