















import { InputSetups } from '../../../../../mixins/input-setups'
import FilterFieldsMixins from '@/views/chat/filters/include/FilterFieldsMixins'

import { UseFields } from 'piramis-base-components/src/components/Pi'

import Component from 'vue-class-component'
import { Mixins } from 'vue-property-decorator'

@Component
export default class IgnoreCaption extends Mixins<UseFields, InputSetups, FilterFieldsMixins>(UseFields, InputSetups, FilterFieldsMixins) {

}
