
















import ExtraSettingsBackButton from '@/views/chat/filters/components/ExtraSettingsBackButton.vue'
import FilterFieldsMixins from '../include/FilterFieldsMixins'

import Component from 'vue-class-component'
import { Emit, Mixins, Prop } from 'vue-property-decorator'

@Component({
  components: {
    ExtraSettingsBackButton,
  }
})
export default class SectionWrapper extends Mixins<FilterFieldsMixins>(FilterFieldsMixins) {
  @Prop({ type: String, required: true }) sectionTitle!: string

  @Emit('onNavBack')
  navBack(): null {
    return null
  }
}
