var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"handle-user-bio"},[_c('switch-input',{attrs:{"setup":{
      'func': _vm.configInputSetup,
      'args': {
        'model': _vm.getModel,
        'key': 'handle_user_bio',
        'tariffTags': _vm.getTagsByFieldKey('handle_user_bio'),
        'hasAnchor': true
      }
    }}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }