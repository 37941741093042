var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"comments-select"},[_c('select-input',{attrs:{"setup":{
      'func': _vm.configInputSetup,
      'args': {
        'model': _vm.field,
        'key': 'comments_mode',
        'options': _vm.commentsModeOptions,
        'clearable': false,
        'tariffTags': _vm.tag,
        'hasAnchor': true
      }
    }}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }