







import Vue from 'vue'

export default Vue.extend({
  'name': "FilterLvlIcon",

  'props': [ 'lvl' ], // 1-5,

  'computed': {
    icon() {
      return require(`!!file-loader!./icons/lvl${ this.lvl }.svg`)
    }
  }
})
