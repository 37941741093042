



























































import { ExtraSettingsMenuItems, FilterTypes } from '@/views/chat/filters/FilterTypes'
import FilterLvlIcon from '@/components/chat/FIlterLvlIcons/FilterLvlIcon.vue'

import ConfigField from 'piramis-base-components/src/components/ConfigField/ConfigField.vue'
import { UseFields } from 'piramis-base-components/src/components/Pi/index'

import Component from 'vue-class-component'
import { Emit, Mixins, Prop } from 'vue-property-decorator'
import { cloneDeep } from 'lodash'

@Component({
  components: {
    FilterLvlIcon,
    ConfigField
  }
})
export default class FilterLevels extends Mixins(UseFields) {

  @Prop() filterType!: FilterTypes

  @Prop({ default: () => [ ExtraSettingsMenuItems.CommonRules ] }) extraMenuItems!: Array<ExtraSettingsMenuItems>

  @Emit('changeCurrentView')
  changeCurrentView(value:ExtraSettingsMenuItems):ExtraSettingsMenuItems {
    return value
  }

  levels = 5

  currentLevel = 0

  levelsCache: Record<string, any> = {}

  get extraMenu(): Array<ExtraSettingsMenuItems> {
    const menuItems: Array<ExtraSettingsMenuItems> = []

    if (this.extraMenuItems.includes(ExtraSettingsMenuItems.AdvancedSettings)) menuItems.push(ExtraSettingsMenuItems.AdvancedSettings)

    if (this.currentLevel === 5) {
      if (this.extraMenuItems.includes(ExtraSettingsMenuItems.VirtualNew)) menuItems.push(ExtraSettingsMenuItems.VirtualNew)
      if (this.extraMenuItems.includes(ExtraSettingsMenuItems.CommonRules)) menuItems.push(ExtraSettingsMenuItems.CommonRules)
    }

    return menuItems
  }

  updateSlider(levelValue: number): void {
    this.levelsCache[this.currentLevel] = cloneDeep(this.$store.state.chatState.configCopy)

    if (!this.levelsCache[levelValue]) {
      if (levelValue !== 5) {
        this.$store.dispatch('setFilterTemplateConfig', {
          configType: this.filterType,
          level: levelValue,
        })
      }
      this.currentLevel = levelValue
    } else {
      this.currentLevel = levelValue
      this.$store.commit('configCopyMutation', { config: cloneDeep(this.levelsCache[this.currentLevel]) })
    }
  }

  compareSlider(): void {
    this.$store.dispatch('filterConfigCompare', this.filterType)
      .then((res: number) => this.currentLevel = res !== -1 ? res : 5)
      .catch(console.error)
  }

  created(): void {
    this.compareSlider()
  }
}
