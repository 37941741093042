var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"from-to"},[_c('config-field',{staticClass:"my-2 py-1",attrs:{"has-anchor":"","field-key":"filter_from_to","title":_vm.$t('filter_from_to')}},[_c('div',{staticClass:"flex flex-col xl:flex-row xl:items-end gap-2"},[_c('time-picker-input',{staticClass:"from-to__picker w-full",attrs:{"setup":{
          'func': _vm.configInputSetup,
          'args': {
            'model': this,
            'key': 'filterFromTargetObject',
            'time24hr': true,
            'clearable': _vm.windowWidth <= 1200
          }
        }}}),_c('time-picker-input',{staticClass:"from-to__picker w-full",attrs:{"setup":{
          'func': _vm.configInputSetup,
          'args': {
            'model': this,
            'key': 'filterToTargetObject',
            'time24hr': true,
            'clearable': _vm.windowWidth <= 1200
          }
        }}}),((_vm.filterFromTargetObject || _vm.filterToTargetObject) && _vm.windowWidth >= 1200)?_c('a-button',{staticClass:"flex-1 sm:flex-none self-end mb-1",attrs:{"icon":"close","type":"danger"},on:{"click":_vm.resetFromTo}}):_vm._e()],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }