var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"common-settings"},[(_vm.isVisible(_vm.CommonSettingFields.Filter_from_to) && _vm.$route.name !== 'warning_system')?_c('from-to',{attrs:{"field":_vm.getModel}}):_vm._e(),(_vm.isVisible(_vm.CommonSettingFields.Comments_mode))?_c('new-comments-select',{attrs:{"field":_vm.getModel}}):_vm._e(),(_vm.isVisible(_vm.CommonSettingFields.Resolve_from_rank))?_c('new-rank-select',{staticClass:"mt-2",attrs:{"field":_vm.getModel}}):_vm._e(),(_vm.isVisible(_vm.CommonSettingFields.Take_away_reputation))?_c('number-input',{attrs:{"setup":{
      'func': _vm.configInputSetup,
      'args': {
        'model': _vm.getModel,
        'key': 'take_away_reputation',
        'min': 0,
        'max': Number.MAX_SAFE_INTEGER,
        'hasAnchor': true,
        'prefix': _vm.customTranslationPrefix
      }
    }}}):_vm._e(),(_vm.isVisible(_vm.CommonSettingFields.Take_away_xp))?_c('number-input',{attrs:{"setup":{
      'func': _vm.configInputSetup,
      'args': {
        'model': _vm.getModel,
        'key': 'take_away_xp',
        'min': 0,
        'max': Number.MAX_SAFE_INTEGER,
        'hasAnchor': true,
        'prefix': _vm.customTranslationPrefix
      }
    }}}):_vm._e(),(_vm.isVisible(_vm.CommonSettingFields.Ban_time_multiplier))?_c('number-input',{attrs:{"setup":{
      'func': _vm.configInputSetup,
      'args': {
        'model': _vm.getModel,
        'key': 'ban_time_multiplier',
        'min': 1,
        'max': Number.MAX_SAFE_INTEGER,
        'hasAnchor': true
      }
    }}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }