











































import { InputSetups } from '../../../../../mixins/input-setups'
import FilterFieldsMixins from '../../include/FilterFieldsMixins'
import { StrictModeFields } from '@/views/chat/filters/FilterTypes'

import { UseFields } from 'piramis-base-components/src/components/Pi'
import NestedContent from 'piramis-base-components/src/components/Pi/components/NestedContent.vue'

import Component from 'vue-class-component'
import { Mixins } from 'vue-property-decorator'

@Component({
  components: {
    NestedContent
  },
  data() {
    return {
      StrictModeFields
    }
  }
})
export default class StrictMode extends Mixins<UseFields, InputSetups, FilterFieldsMixins>(UseFields, InputSetups, FilterFieldsMixins) {
  get showUseCustomDict(): boolean {
    return this.getModelFromConfigRoot[`${ this.filterType }_strict_mode`]
  }

  get showCustomDict(): boolean {
    return this.getModelFromConfigRoot[`${ this.filterType }_use_custom_dict`]
  }

}
