import {
  CommonRulesFields,
  BaseSpecialSettingFields,
  VirtualNewFields,
  StrictModeFields,
  CommonSettingFields,
  FilterTypes
} from "@/views/chat/filters/FilterTypes";

import Component from "vue-class-component";
import Vue from "vue";
import { Emit, Prop } from "vue-property-decorator";
import { get } from "lodash";

type ConfigPath = string

@Component
export default class FilterFieldsMixins extends Vue {
  @Prop() filterType!: FilterTypes

  @Prop() storePath!: ConfigPath

  @Prop({ default: null }) pickFields!: Array<CommonRulesFields | VirtualNewFields | BaseSpecialSettingFields | StrictModeFields | CommonSettingFields> | null

  @Prop({ type: Boolean, required: false, default: false }) customTranslation!: boolean

  @Emit('onNavBack')
  navBack(): null {
    return null
  }

  get getModel(): any {
    const rootModelPath = `${ this.storePath }.${ this.filterType }`
    return get(this, rootModelPath)
  }

  get getModelFromConfigRoot(): any {
    return get(this, this.storePath)
  }

  get customTranslationPrefix() {
    return this.customTranslation ? `${ this.filterType }_` : ''
  }

  isVisible(fieldName: CommonRulesFields | VirtualNewFields | BaseSpecialSettingFields | StrictModeFields | CommonSettingFields): boolean {
    if (this.pickFields) {
      return this.pickFields.includes(fieldName)
    }
    return true
  }

}
