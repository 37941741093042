































































import NewCommentsSelect from '@/views/chat/filters/components/Fields/NewCommentsSelect.vue'
import NewRankSelect from '@/views/chat/filters/components/Fields/NewRankSelect.vue'
import { CommonSettingFields } from '@/views/chat/filters/FilterTypes'
import FilterFieldsMixins from '@/views/chat/filters/include/FilterFieldsMixins'
import FromTo from '@/views/chat/filters/components/Fields/FromTo.vue'
import { InputSetups } from '@/mixins/input-setups'

import { UseFields } from 'piramis-base-components/src/components/Pi'

import { Mixins } from 'vue-property-decorator'
import Component from 'vue-class-component'

@Component({
  components: {
    FromTo,
    NewRankSelect,
    NewCommentsSelect,
  },
  data() {
    return {
      CommonSettingFields
    }
  }
})
export default class CommonSettings extends Mixins<FilterFieldsMixins>(UseFields, InputSetups, FilterFieldsMixins) {

}
